<template>
    <div class="billing-transfer-modal">
        <vs-popup class="last-client-bank-documents-modal--popup" title="Letzte Rechnungen des Kunden anzeigen" @close="onClose"
                  :active="visible">
            <div v-show="dataTransfer">

                <div class="vx-row mb-2 p-4">
                    <div class="vx-col  w-full mb-0">

                        <vs-row class="mt-4 bg-gray-400 p-5" v-if="document">
                          <h3 class="d-block w-full mb-3">Dokumentdaten:</h3>
                          <div class="vx-col  w-4/12 mb-0">
                            <small>Belegdatum:</small>  <span v-if="document.document_date">{{ document.document_date }} </span> <i v-else>-- keine Angabe --</i><br>
                            <small>Belegnummer:</small> <span v-if="document.document_number">{{ document.document_number }} </span> <i v-else>-- keine Angabe --</i>
                          </div>
                          <div class="vx-col  w-4/12 mb-0">
                            <small>Summe:</small> <span v-if="document.sum_netto">{{ document.sum_netto }} </span> <i v-else>-- keine Angabe --</i>  |  <span v-if="document.sum_brutto">{{ document.sum_brutto }} </span> <i v-else>-- keine Angabe --</i><br>
                            <small>Notiz:</small> <i v-if="document.notice">{{ document.notice }} </i> <i v-else>-- keine Angabe --</i>
                          </div>
                          <div class="vx-col  w-4/12 mb-0">
                            <small>Dokument ansehen:</small> LINK TODO <br>
                           <b>Alle Dokumente von Lieferant/Kunde<span v-if="document.client">: {{document.client.company_name}}</span><span v-else> ID: {{document.client_id}}</span></b>
                          </div>
                        </vs-row>


                      <vue-bootstrap4-table :rows="data" :columns="columns" :config="config" :actions="actions" class="scrollable mt-3">

                        <template slot="row_actions" slot-scope="props">
                          <vs-button color="dark" type="filled" class="mr-3 mb-auto" size="small"
                                     target="_blank"
                                     :href="`${props.row.file_url}`"
                                     v-if="props.row.file">
                            ansehen
                          </vs-button>
                        </template>

                        <template slot="id" slot-scope="props">
                          {{props.row.id}}
                         <b v-if="props.row.id === document.id" style="color:green">
                           <br>
                            (DIESES Dokument)
                         </b>
                        </template>

                        <template slot="document_date" slot-scope="props">
                         <span v-bind:class="[formatDate(props.row.document_date) === document.document_date ? 'font-semibold' : '']">
                           {{ formatDate(props.row.document_date) }}
                         </span>
                        </template>

                        <template slot="document_number" slot-scope="props">
                         <span v-bind:class="[
                             props.row.document_number === document.document_number ||
                             props.row.document_number.indexOf(document.document_number) != -1 ||
                             document.document_number.indexOf(props.row.document_number) != -1 ? 'font-semibold' : '']">
                           {{ props.row.document_number }}
                         </span>
                        </template>

                        <template slot="sum_netto" slot-scope="props">
                         <span v-bind:class="[props.row.sum_netto === document.sum_netto ? 'font-semibold' : '']">
                           {{ formatCurrency(props.row.sum_netto) }}
                         </span>
                        </template>

                        <template slot="sum_brutto" slot-scope="props">
                         <span v-bind:class="[props.row.sum_brutto === document.sum_brutto ? 'font-semibold' : '']">
                             {{ formatCurrency(props.row.sum_netto) }}
                         </span>
                        </template>

                        <template slot="bank_transaction_id" slot-scope="props">
                         <small v-if="props.row.bank_transaction_id != null">
                            Zugewiesen am: {{ formatDate(props.row.bank_transaction.booking_date) }}<br>
                            Betrag: {{ formatCurrency(props.row.bank_transaction.sum) }}<br>
                            Verwendungszweck: {{ props.row.bank_transaction.reference_text }}<br>
                         </small>
                          <span v-else>- nicht zugewiesen -</span>
                        </template>



                        <template slot="empty-results">
                          Keine Dokumente gefunden.
                        </template>
                      </vue-bootstrap4-table>


                    </div>
                </div>

            </div>

            <div class="flex p-2 justify-end  rounded-b">
                <vs-button color="danger" type="border" @click="onClose" class="mr-2">Schließen</vs-button>
            </div>
        </vs-popup>
    </div>
</template>

<script>
    import ApiService from "../../api";
    import staticOptions from "../../mixins/static/options";
    import MgSelect from "../../components/mg-select/MgSelect";
    import * as qs from "qs";
    import moment from 'moment';
    import PriceHelper from "../../mixins/helper/price.helper";
    import {mapGetters} from "vuex";
    import VueBootstrap4Table from '@/plugins/vue-bootstrap4-table-master/src/components/VueBootstrap4Table'
    import QueryHelper from "@/mixins/helper/query.helper";


    export default {
        name: "last-client-bank-documents-modal",
        components: {
            MgSelect,
          VueBootstrap4Table
        },
        props: {
            document: {
                default: null
            },
            active: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                dataTransfer: {},
                categories: [],

              /* table */
              data: [],
              actions: [],
              columns: [
                {
                  label: "ID",
                  name: "id",
                  filter: {
                    type: "simple",
                    placeholder: "ID suchen..."
                  },
                  slot_name: "id",
                  sort: true,
                  row_text_alignment: 'text-left',
                  column_text_alignment: 'text-left'
                },
                {
                  label: "Belegdatum",
                  name: "document_date",
                  filter: {
                    type: "simple",
                    placeholder: "suchen..."
                  },
                  formatter: (value) => {
                    return moment(new Date(value)).format('DD.MM.YYYY');
                  },
                  slot_name: "document_date",
                  sort: true,
                  row_text_alignment: 'text-left',
                  column_text_alignment: 'text-left'
                }, {
                  label: "Belegnummer",
                  name: "document_number",
                  filter: {
                    type: "simple",
                    placeholder: "suchen..."
                  },
                  slot_name: "document_number",
                  sort: true,
                  row_text_alignment: 'text-left',
                  column_text_alignment: 'text-left'
                },
                {
                  label: "Summe netto",
                  name: "sum_netto",
                  sort: true,
                  filter: {
                    type: "simple",
                    placeholder: "suchen..."
                  },
                  slot_name: "sum_netto",
                  row_text_alignment: 'text-left',
                  column_text_alignment: 'text-left'
                },
                {
                  label: "Summe brutto",
                  name: "sum_brutto",
                  sort: true,
                  filter: {
                    type: "simple",
                    placeholder: "suchen..."
                  },
                  slot_name: "sum_brutto",
                  row_text_alignment: 'text-left',
                  column_text_alignment: 'text-left'
                },
                {
                  label: "Notiz",
                  name: "notice",
                  sort: true,
                  filter: {
                    type: "simple",
                    placeholder: "suchen..."
                  },
                  slot_name: "notice",
                  row_text_alignment: 'text-left',
                  column_text_alignment: 'text-left'
                },{
                  label: "Bank zugewiesen?",
                  name: "bank_transaction_id",
                  sort: true,
                  slot_name: "bank_transaction_id",
                  row_text_alignment: 'text-left',
                  column_text_alignment: 'text-left'
                },{
                  label: "Erstellt am",
                  name: "created_at",
                  sort: true,
                  filter: {
                    type: "simple",
                    placeholder: "suchen..."
                  },
                  formatter: (value) => {
                    return moment(new Date(value)).format('DD.MM.YY - HH:mm');
                  },
                  row_text_alignment: 'text-left',
                  column_text_alignment: 'text-left'
                },
                {
                  label: "Actions",
                  slot_name: "row_actions",
                  sort: false,
                  row_text_alignment: 'text-left',
                  column_text_alignment: 'text-left',
                  column_classes: 'actions-column'
                }],
              config: {
                checkbox_rows: false,
                rows_selectable: false,
                page: 1,
                per_page: 30,
                show_refresh_button: false,
                show_reset_button: false,
                highlight_row_hover_color: '#f8f8f8',
                global_search:  {
                  visibility:  false,
                },
              },
              documentQueryParams: {
                sort: [],
                filters: [],
                global_search: "",
                per_page: 500,
                page: 1,
              },




            }
        },
        created() {
          this.fetchData()
        },
        computed: {
            ...mapGetters(['userHasPermission', 'userId', 'user']),
            visible() {
                return this.active;
            },
            formValid() {
              return !this.errors.any();
            },
          requestDocumentParams() {
            let queryParams = QueryHelper.parseRequestParams(this.documentQueryParams);


              queryParams.filter.push({
                field: 'client_id',
                value:  this.document.client_id,
                expression: 'exact'
              });

            queryParams.order =
                {
                  'field': 'document_date',
                  'direction': 'desc'
                }
            ;

            return queryParams;
          },
        },
        methods: {
            openModal() {

              if(!this.dataTransfer) {
                this.dataTransfer = JSON.parse(JSON.stringify(this.newData));
              }
              this.fetchData();

            },
          fetchData() {
            this.$vs.loading()
            ApiService.get('bank-transactions/document', {
              params: this.requestDocumentParams,
              paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
            }).then(response => {
              this.data = response.data.result;
              this.$vs.loading.close()
            }).catch(response => {
            })
          },
            onClose() {
                this.$emit('update:active', false)
                this.$emit('close')
            },
            fetchClients() {
                return ApiService.get(`clients`)
                    .then(response => {
                        if (response.status == 200) {
                            this.clients = response.data.result;
                        } else {
                            this.$vs.notify({
                                title: 'Fehler beim Kunden laden',
                                text: '',
                                iconPack: 'feather',
                                icon: 'icon-alert-circle',
                                color: 'danger'
                            })
                        }
                    })
                    .catch((error) => {
                        this.$vs.notify({
                            title: 'Fehler beim Kunden laden',
                            text: '',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    });
            },
            customClientLabel(option) {
                return `${option.customer_number} - ${option.company_name}`;
            },
            customOfferLabel(option) {
                return `${option.invoice_number} - ${option.title} - ${option.order_date}`;
            },
            formatDate(value) {
                return moment(new Date(value)).format('DD.MM.YYYY')
            },
            formatCurrency(number) {
              return number + ' €';
              return PriceHelper.floatPointToComma(number) + ' €';
            },

        },
        watch: {
            active: function (val) {
                if (val === true) {
                    this.openModal();
                }
            },
        }
    }
</script>

<style lang="scss">
    .last-client-bank-documents-modal--popup {
        .vs-popup {
            min-width: 1600px;
            max-width: 100vw;
        }

        .vs-popup--content {
            padding: 5px 0 0 0;
            margin: 0;
            min-height: 60px;
            width: 100%;
        }
      .documentprice-badge {
        top: -8px;
        position: relative;
        font-size: 10px;
      }
      .scrollable {
        max-height: 500px;
        overflow-y: scroll;
      }
    }
</style>
