<template>
  <vs-prompt
      @accept="createClient"
      title="Lieferant/Kunde erstellen"
      @close="$emit('close')"
      acceptText="Lieferant/Kunde erstellen"
      cancelText="Abbrechen"
      :active.sync="activePrompt">

    <vs-input label="Kundennummer" v-model="clientData.customer_number" class="w-full mb-2" readonly disabled=""></vs-input>
    <vs-input label="Unsere KD-Nr. beim Lieferanten (Lieferantennummer)" v-model="clientData.supplier_number" class="w-full mb-2"></vs-input>

    <vs-row>
      <vs-col vs-align="center" vs-w="6" class="pr-2">
        <div class="form-group">
          <vs-input label="Firmenname (inkl. Firmierung)" name="company_name" v-model="clientData.company_name" autocomplete="off" class="w-full mb-2" v-validate="'required'" data-vv-as="Firma" ></vs-input>
          <span class="text-danger text-sm" v-if='errors.has("company_name")'>Dieses Feld ist ein Pflichtfeld</span>
        </div>
      </vs-col>
      <vs-col vs-align="center" vs-w="6">
        <div class="form-group pt-5">
          <mg-select :options="typeOptions" v-model="clientData.type" @select="onSelectCustomerType"
                     @remove="clientData.type=null"
                     placeholder="Type auswählen" track-by="value" label="label"
          >
          </mg-select>
        </div>
      </vs-col>
    </vs-row>

    <vs-row>
      <vs-col vs-align="center" vs-w="6" class="pr-2">
        <div class="form-group">
          <vs-input label="Straße" v-model="clientData.address_line_1"  autocomplete="off" class="w-full mb-2" ></vs-input>
        </div>
      </vs-col>
      <vs-col vs-align="center" vs-w="2">
        <div class="form-group">
          <vs-input label="PLZ" v-model="clientData.zip_code" autocomplete="off" class="w-full mb-2" ></vs-input>
        </div>
      </vs-col>
      <vs-col vs-align="center" vs-w="4">
        <div class="form-group">
          <vs-input label="Ort/Stadt" v-model="clientData.city" autocomplete="off" class="w-full mb-2" ></vs-input>
        </div>
      </vs-col>
    </vs-row>

    <vs-row>
      <vs-col vs-align="center" vs-w="6" class="pr-2">
        <div class="form-group">
          <vs-select label="Land" class="w-full mb-2" v-model="clientData.country"  autocomplete="off">
            <vs-select-item :key="index" :value="item.value" :text="item.label"  autocomplete="off"
                            v-for="item, index in countries"/>
          </vs-select>
        </div>
      </vs-col>
      <vs-col vs-align="center" vs-w="6">
        <div class="form-group">
          <vs-input label="E-Mail Adresse" v-model="clientData.email" autocomplete="off" class="w-full mb-2" ></vs-input>
        </div>
      </vs-col>
    </vs-row>

    <vs-row>
      <vs-col vs-align="center" vs-w="12" >
        <div class="form-group">
          <vs-input label="Bank Empfängername" v-model="clientData.bank_account_owner" autocomplete="off" class="w-full mb-2" ></vs-input>
        </div>
      </vs-col>
    </vs-row>

    <vs-row>
      <vs-col vs-align="center" vs-w="8" class="pr-2">
        <div class="form-group">
          <vs-input label="IBAN" v-model="clientData.bank_iban" autocomplete="off" class="w-full mb-2" ></vs-input>
        </div>
      </vs-col>
      <vs-col vs-align="center" vs-w="4">
        <div class="form-group">
          <vs-input label="BIC" v-model="clientData.bank_bic" autocomplete="off" class="w-full mb-2" ></vs-input>
        </div>
      </vs-col>
    </vs-row>



  </vs-prompt>
</template>

<script>
import ApiService from "../../api";
import staticOptions from "@/mixins/static/options";
import MgSelect from "@/components/mg-select/MgSelect";
import {mapGetters} from "vuex";

export default {
  name: "CreateClientModal",
  components: {MgSelect},
  props: [
    'active'
  ],
  computed: {
    ...mapGetters([
     'workspace'
    ]),
    typeOptions() {
      return staticOptions.clientTypes;
    },
  },
  data() {
    return {
      countries: [],
      activePrompt: false,
      transfer_notice: "",
      nextCustomerNumbers: [],
      clientData: {
        "workspace_id": null,
        "business_client": 0,
        "type": "supplier",
        "customer_number": 0,
        "company_name": "",
        "address_line_1": "",
        "address_line_2": "",
        "fast_registration": 1,
        "zip_code": "",
        "city": "",
        "country": 1,
        "phone": null,
        "fax": null,
        "websites": null,
        "ust_id": null,
        "payment_deadline": 14,
        "internal_comment": null,
        "bank_iban": "",
        "bank_bic": "",
        "bank_account_owner": "",
        "email": "",
        "internal_notice": null
      },
    }
  },
  created() {
    this.clientData.workspace_id = this.workspace.id
    this.fetchCountries();
    this.fetchInitialData();
  },
  methods: {
    fetchCountries() {
      this.countries = staticOptions.countries;
    },
    onSelectCustomerType(type){
      this.getNextFreeCustomerNumber(type.value)
    },
    getNextFreeCustomerNumber(type){
      this.clientData.customer_number = this.nextCustomerNumbers[type]
    },
    fetchInitialData() {
      ApiService.get('clients/getNextCustomerNumberByTypes').then((response) => {
        if(response.data.result) {
          this.nextCustomerNumbers =  response.data.result;
          this.clientData.customer_number =  response.data.result[this.clientData.type];
        }
        this.fetched = true;
      })
    },
    activeChange(value) {
      this.activePrompt = value;
    },
    createClient() {
      this.$validator.validate().then(valid => {
        if (valid) {

            ApiService.post('clients', this.clientData).then((response) => {

              if(response.data.status === "success") {
                this.$emit('accept');
                return this.$vs.notify({
                  title: 'Erfolgreich',
                  text: 'Der Lieferant wurde erfolgreich erstellt',
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'success'
                })
              }

              this.$vs.notify({
                title: 'Fehlgeschlagen',
                text: 'Der Lieferant konnte nicht erstellt werden.',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              })
            }).catch((error) => {
              this.$vs.notify({
                title: 'Fehlgeschlagen',
                text: 'Der Lieferant konnte nicht erstellt werden.',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              })
            });
        } else {
          return false;
        }
      })




    }
  },
  watch: {
    active: 'activeChange'
  }
}
</script>

<style lang="scss">
.vs-tooltip {
  z-index: 7000000;
}
.error-icon {
  top: 0px;
  position: relative;
  margin-left: 5px;
  color: #e5260e;
  font-size: 18px;
}
</style>